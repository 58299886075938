@use '../../scss/variables' as v;
@import '../../scss/mixins';

.colTable {
  height: 50px;
  border-right: 1px solid v.$border-cl;
  display: flex;
  align-items: center;
}
.titleCol {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  height: 100%;
}
.editable_cell_value_wrap {
  display: flex;
  width: 100%;
  height: 22px;
  justify-content: flex-end;
}
