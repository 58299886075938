@use '../../scss/variables' as v;

.chartTitleWrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-bottom: 10px;
}

.line {
  width: 40%;
  height: 1px;
  background-color: v.$black-cl;
  margin: 7px 0;
}

.title {
  font-size: v.$text-bg;
  font-weight: v.$font-weight-normal;
  margin-left: 10px;
}

.subTitle {
  font-size: v.$text-sm-size;
  font-weight: v.$font-weight-normal;
  margin-left: 10px;
}
