@use '../../scss/variables' as v;
@import '../../scss/mixins';

.listWrap {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  li {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ced4da;
    &.itemActive {
      background: #e9ecef;
    }
    > div {
      height: 40px;
      display: flex;
      align-items: center;
      padding: 8px;
      :global(.ant-input-number) {
        height: 38px;
        width: 78px;
        border: 1px solid #ced4da;
        border-radius: 4px;
        margin: 0 4px;
        :global(.ant-input-number-input) {
          height: 38px;
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          color: #6c757d;
        }
      }
      span {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #212529;
      }
    }
    > div:first-child {
      width: 3.2%;
    }
    > div:nth-child(2) {
      width: 20%;
    }
    > div:last-child {
      width: 76.8%;
    }
    &:first-child {
      border-bottom: 2px solid #212529;
      &.itemActive {
        background: none;
      }
      div {
        span {
          font-weight: 700;
        }
      }
      > div:nth-child(2) {
        visibility: hidden;
      }
    }
  }
}
