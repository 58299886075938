@use '../../../scss/variables' as v;
@import '../../../scss/mixins';

.groups {
  padding: 40px 70px;
  height: 100%;
  &__wrap {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
}

.top {
  height: calc(100% - 50px);
  flex: 1;
  overflow: auto;
}

.bottom {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  height: 100%;
  overflow: hidden;
}

.action {
  text-align: end;
}

.table {
  margin-top: 20px;
}
