@use '../../scss/variables' as v;

.mainButton {
  width: 100%;
  height: 100%;
  background-color: v.$orange-cl !important;
  border-color: v.$orange-cl !important;
  border-radius: 4px;
}

.drakButton {
  width: 100%;
  height: 100%;
  background-color: v.$hamburger-button-gray !important;
  border-color: v.$hamburger-button-gray !important;
  border-radius: 4px;
}
