@use '../../../scss/variables' as v;
@import '../../../scss/mixins';

.assignmentsQuestionWrap {
  width: 100%;
  height: calc(100% - 40px);
  padding: 10px 30px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  line-height: 24px;
}

.title {
  margin-left: 76px;
  @include mobile {
    margin-left: 16px;
  }
}

.contentRight {
  justify-content: end;
}

.content {
  height: 100%;
  flex: 1;
  overflow: auto;
  padding-top: 35px;
  @include mobile {
    margin: 10px;
  }
}

.contentWrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  &__left {
    height: 100%;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.175);
    overflow: auto;
    display: flex;
    flex-direction: column;
  }
}

.contentTitle {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.175);
  background: rgba(0, 0, 0, 0.03);
  height: 50px;
  padding-left: 15px;
}

.form {
  padding: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  &__top {
    display: flex;
    width: 100%;
    .left {
      width: 50%;
    }
    .right {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      ul {
        li {
          display: flex;
          justify-content: flex-end;
          text-align: right;
          span {
            margin-left: 10px;
          }
        }
      }
    }
    @include mobile {
      flex-direction: column;
      .left,
      .right {
        width: 100%;
      }
      .right {
        align-items: flex-start;
      }
    }
  }
  &__bottom {
    margin-top: 20px;
    height: 100%;
    flex: 1;
  }
}

.textRed {
  color: red;
}
