@use '../../../scss/variables' as v;
@import '../../../scss/mixins';

.assignmentsMenuWrap {
  padding: 40px 70px;
  height: 100%;
  overflow: auto;
  @include tablet-custom {
    padding: 24px;
  }
  @include mobile {
    padding: 8px;
  }
  .cardWrap {
    height: 100%;
    overflow: auto;
    .assignmentsMenuTitle {
      display: flex;
      p {
        padding-right: 20px;
      }
    }
    .assignmentsMenuContent {
      padding: 40px 80px;
      @include tablet-custom {
        padding: 40px 60px;
      }
      > p {
        margin-bottom: 20px;
        font-weight: 400;
        font-size: 22px;
        line-height: 150%;
        color: #4f4f4f;
      }
      @include mobile {
        padding: 0;
        p {
          margin: 0;
        }
      }
      :global(.ant-col),
      .card {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
      .card {
        padding: 8px 16px;
        max-width: 240px;
        width: 100%;
        height: 78px;
        border: 1px solid $gray-600;
        border-radius: 4.8px;
        @include tablet-custom {
          width: 100%;
        }
        @include mobile {
          width: 100%;
          white-space: normal;
        }
        .titleTop {
          display: flex;
          justify-content: center;
          flex-direction: row;
        }
        .titleBottom {
          justify-content: center;
          display: flex;
          flex-direction: row;
        }
      }
      .card,
      .status {
        font-weight: 300;
        font-size: 20px;
        line-height: 150%;
        color: $gray-600;
      }
      .status {
        text-align: center;
        padding-top: 10px;
        &.statusNotEntered {
          color: $primary_t1;
        }
        .titleTop {
          flex-direction: row;
        }
      }
    }
  }
}
