@use '../../../scss/variables' as v;
@import '../../../scss/mixins';

.assignmentsResultsWrap {
  width: 100%;
  height: calc(100%);
  padding: 10px 30px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  line-height: 24px;
  .questionWrap {
    margin: 10px 0;
    .questionText {
      margin: 10px 0;
    }
  }
}

.contentRight {
  justify-content: end;
}

.tabs {
  height: 100%;
  flex: 1;
  overflow: auto;
  padding-top: 24px;
  @include mobile {
    margin: 10px;
  }
}

.buttonLeft {
  margin-right: 10px;
  @include mobile {
    margin-right: 0;
  }
}
.mainTitle {
  font-size: 22px;
}
.title {
  font-size: 22px;
  margin-left: 76px;
}
