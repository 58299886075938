@use '../../scss/variables' as v;
@import '../../scss/mixins';

.mainHeader {
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 8px solid v.$dark-blue-cl;
  background-color: v.$paleBrown;
  padding: 0 24px;
  &__left {
    margin-left: 42px;
    display: flex;
    align-items: center;
    height: 100%;
  }
  &__right {
    display: flex;
    align-items: center;
    color: v.$black-cl;
    height: 100%;
    p {
      padding: 0px 12px 0px 18px;
    }
  }
}

.mainLogo {
  cursor: pointer;
  @include mobile {
    display: none;
  }
}

.hamburgerLines {
  display: flex;
  height: 23px;
  width: 28px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-right: 50px;
  cursor: pointer;
}

.line {
  display: flex;
  height: 2px;
  width: 100%;
  border-radius: 10px;
  background: v.$black-cl;
}

.arrowDownWrapper {
  padding: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  .arrowDown {
    width: 9px;
    height: 9px;
    border: solid v.$black-cl;
    border-width: 0 2px 2px 0;
    display: inline-block;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
}
.groupsDownWrapper {
  padding: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 2rem;
  .arrowDown {
    width: 9px;
    height: 9px;
    border: solid v.$black-cl;
    border-width: 0 2px 2px 0;
    display: inline-block;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
}

.dropDownUser {
  &:global(.ant-dropdown) {
    :global(.ant-dropdown-menu) {
      border: 1px solid rgba(0, 0, 0, 0.15);
      padding: 8px 0;
      border-radius: 4px;
      box-shadow: none;
      :global(.ant-dropdown-menu-item) {
        width: 100%;
        min-width: 162px;
        font-weight: $font-weight-normal;
        font-size: 16px;
        line-height: $text-line-height;
        color: $gray-900;
      }
    }
  }
}

// Modal Groups
.contentModal {
  overflow: hidden;
  .tableWrapper {
    overflow-y: scroll;
    height: 60vh;
  }
  .paginationWrapper {
    margin-top: 1rem;
    text-align: center;
  }
}
