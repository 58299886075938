@use '../../../scss/variables' as v;
@import '../../../scss/mixins';

.groups {
  padding: 40px 70px;
  height: 100%;
  &__wrap {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
  }
}

.top {
  height: calc(100% - 50px);
  flex: 1;
  overflow: auto;
}

.bottom {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.content {
  height: 100%;
}

.menuTitle {
  font-size: 22px;
}
.topButtonGroup {
  margin-bottom: 22px;
}

.groupButton {
  width: 100%;
  justify-content: space-around;
  :global {
    .ant-space-item {
      min-width: 100px;
    }
  }
  span {
    font-size: 14px;
  }
}

:global {
  .ant-table-cell {
    padding: 0.5rem !important;
  }
}
