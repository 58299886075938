.ant-checkbox-wrapper {
  $checkbox-color: $primary_t1;
  .ant-checkbox {
    .ant-checkbox-inner {
      width: 16px;
      height: 16px;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.15);
    }
    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: $checkbox-color;
        border-color: $checkbox-color;
      }
      &::after {
        border: 1px solid $checkbox-color;
        border-radius: 4px;
      }
    }
    &.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
      border-color: rgba(255, 255, 255, 0.65);
    }
    &.ant-checkbox-indeterminate {
      .ant-checkbox-inner {
        &::after {
          border-radius: 2px;
          background-color: $checkbox-color;
        }
      }
    }
  }
  &:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $checkbox-color;
  }
}

.ant-form {
  .ant-form-item {
    .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      display: none;
    }
    .ant-form-item-explain {
      margin-bottom: 24px;
      text-align: left;
      font-size: 12px;
    }
  }
}
.ant-input-number {
  .ant-input-number-handler-wrap {
    display: none;
  }
}

.ant-pagination {
  margin-top: auto;
}

.ant-card {
  .ant-card-head {
    min-height: auto;
    padding: 15px 24px;
    margin: 0;
    .ant-card-head-wrapper {
      gap: 24px;
      .ant-card-head-title {
        padding: 0;
        font-weight: 400;
        font-size: 20px;
        line-height: 31px;
        color: #4f4f4f;
      }
    }
  }
  .ant-card-body {
    padding: 18px 24px;
  }
}

.ant-table {
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 8px;
  }
}

.ant-image {
  .ant-image-img {
    object-fit: cover;
  }
}
