@use '../../../scss/variables' as v;
@import '../../../scss/mixins';

.assignmentsQuestionWrap {
  width: 100%;
  height: calc(100% - 40px);
  padding: 10px 30px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  line-height: 24px;
}

.title {
  margin-left: 76px;
  @include mobile {
    margin-left: 16px;
  }
}

.contentRight {
  justify-content: end;
}

.content {
  height: 100%;
  flex: 1;
  overflow: hidden;
  padding-top: 35px;
  @include mobile {
    margin: 10px;
  }
}

.contentWrap {
  height: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  .item {
    border: 1px solid rgba(0, 0, 0, 0.175);
    height: 40%;
    &__top {
      display: flex;
      align-items: center;
      height: 50px;
      overflow: hidden;
      border-bottom: 1px solid rgba(0, 0, 0, 0.175);
      font-size: v.$text-bg;
      background: rgba(0, 0, 0, 0.03);
      padding-left: 16px;
    }
    &__bottom {
      overflow: auto;
      height: calc(100% - 50px);
      padding: 16px;
    }
  }
  .item2 {
    margin-top: 20px;
    height: calc(60% - 20px) !important;
  }
}

.contentTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid v.$border-cl;
  background: rgba(0, 0, 0, 0.03);
  height: 50px;
}

.card {
  height: 100%;
  overflow: auto;
}

.imgName {
  margin-left: 10px;
}
