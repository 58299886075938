@use '../../scss/variables' as v;

.buttonFooter {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: v.$gray-cl;
  color: v.$text-gray-cl;
}

.buttonWrap {
  width: 120px;
  margin: 10px;
}

.buttonFinish {
  margin: 10px;
}
