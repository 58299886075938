@use '../../scss/variables' as v;

.cardWrap {
  text-align: center;
  width: 438px;
}

.formWarp {
  padding: 0 16px;
}

.forgotPw {
  margin-left: 16px;
}

.text {
  text-align: left;
}
