@use '../../scss/variables' as v;

.card {
  display: flex;
  justify-content: center;
  padding: 0 !important;
}

.cardContent {
  ul {
    line-height: '4rem';
    li {
      font-weight: v.$text-title-font-weigth;
      font-size: v.$text-title;
      line-height: v.$text-line-height;
    }
  }

  .borderR {
    border-right: 1px solid;
  }
  .borderB {
    border-bottom: 1px solid;
  }

  .textItem {
    height: 40px;
    display: flex;
    justify-content: space-between;
  }
}

.goal {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #f0f0f0;
  align-items: center;
  height: 80px;
}

.index {
  width: 50px;
  border-right: 1px solid #f0f0f0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #f0f0f0;
  height: 100%;
}

.goalOk {
  color: v.$green;
}

.goalNg {
  color: v.$red;
}
