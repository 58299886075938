@use '../../../scss/variables' as v;
@import '../../../scss/mixins';

.assignmentsQuestionWrap {
  width: 100%;
  height: calc(100% - 40px);
  padding: 10px 30px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  line-height: 24px;
}

.title {
  margin-left: 76px;
}

.contentRight {
  justify-content: end;
}

.content {
  height: 100%;
  flex: 1;
  overflow: auto;
  @include mobile {
    margin: 10px;
  }
}

.contentWrap {
  height: 100%;
  display: flex;
  &__left {
    width: 55%;
    border: 1px solid v.$border-cl;
    border-right: 0;
    overflow: auto;
  }
  &__right {
    width: 45%;
    border: 1px solid v.$border-cl;
    border-left: 0;
    overflow: auto;
  }
}

.contentTitle {
  display: flex;
  align-items: center;
  margin-top: 35px;
  padding-left: 10px;
  border: 1px solid v.$border-cl;
  border-bottom: 0;
  background: rgba(0, 0, 0, 0.03);
  height: 50px;
  font-size: larger;
}

.form {
  padding: 0px 10px 0 10px;
  border-radius: 4px;
  &__row {
    display: flex;
    .item {
      width: 20%;
      margin-right: 20px;
    }
  }
}

.pForm {
  padding-left: 10px;
}
.formRight {
  padding-left: 10px !important;
}
.buttonAdd {
  border: 0 !important;
  margin-left: 28%;
}
.iconAdd {
  font-size: large;
  color: v.$primary_t1 !important;
}
.iconRemove {
  font-size: large;
  color: v.$blue-cl !important;
}
.formItem {
  display: flex;
  padding: 5px;
  border: 1px solid v.$border-cl;
  &__left {
    width: 60%;
    margin-bottom: 0px !important;
  }
  &__right {
    width: 40%;
    margin-bottom: 0px !important;
    margin-left: 5px !important;
  }
}

.li {
  display: flex;
}
