@use '../../../scss/variables' as v;
@import '../../../scss/mixins';

.assignmentsRfpWrap {
  width: 100%;
  height: calc(100% - 40px);
  padding: 10px 30px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  line-height: 24px;
}

.title {
  margin-left: 76px;
  @include mobile {
    margin-left: 16px;
  }
}

.contentRight {
  justify-content: end;
}

.contentWrap {
  height: 80%;
  flex: 1;
  border: 1px solid v.$black-cl;
  margin: 30px 70px;
  padding: 16px;
  &__bottom {
    display: flex;
    margin-top: 20px;
    @include mobile {
      flex-direction: column-reverse;
    }
    .left {
      width: 80%;
      @include mobile {
        width: 100%;
      }
    }
    .right {
      width: 20%;
      @include mobile {
        width: 100%;
      }
    }
  }
  @include tablet-custom {
    margin: 20px;
  }
  @include mobile {
    height: 70%;
    margin: 10px;
  }
}

.sliderItem {
  flex: 1;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  overflow: auto;
  span {
    font-size: 24px;
    line-height: 36px;
    margin-top: 12px;
    display: block;
  }
  .sliderItem_button {
    background-color: #ed6c01;
    display: inline;
    margin: 0 3px;
    border-color: #ed6c01;
    height: 32px;
    padding: 0 15px;
    font-size: 14px;
    line-height: 2.5715;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    border-radius: 2px;
    color: #fff;
  }
  .textSlider {
    font-size: 18px;
    margin-top: -0.25rem;
    margin-bottom: 0.5rem;
  }
  .imgWrap {
    margin-top: 1rem;
    width: 100%;
    height: 78%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    @include tablet-custom {
      width: 100%;
      margin-top: 1rem;
    }
    @include mobile {
      height: 100%;
      margin-top: 0;
    }
    .img {
      object-fit: contain;
      height: 100%;
      max-width: 70vw;
    }
  }
  .itemsTitle {
    font-size: 18px;
    @include tablet {
      font-size: 16px;
    }
  }
  .itemTable {
    border: 1px solid;
    margin: 16px 107px 0 110px;
    .table {
      border: 1px solid;
      display: flex;
      justify-content: space-between;
      .colsFirst {
        padding: 3px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 25%;
        border-right: 1px solid;
        p {
          font-size: 22px;
          display: flex;
          align-items: center;
        }
        img {
          padding-right: 12px;
        }
        @include tablet {
          flex-direction: column;
          p {
            font-size: 16px;
          }
          img {
            padding-right: 0px;
            width: 100%;
          }
        }
      }
      .colsSecond {
        width: 75%;
        margin-left: 12px;
        font-size: 18px;
        display: flex;
        align-items: center;
        text-align: start;
        white-space: pre-line;
        @include tablet {
          font-size: 18px;
        }
      }
    }
    @include mobile {
      margin: 0 !important;
    }
  }
}

.slider {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.step3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.table3 {
  border: 1px solid #000;
  margin-top: 20px;
  margin-left: 110px;
  margin-right: 110px;
  @include mobile {
    margin: 0 !important;
  }
}
.rowtable {
  height: 60px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.301);
  line-height: 60px;
  font-size: 22px;
  font-weight: 500;
}
.col1 {
  border-right: 1px solid #000;
  @include mobile {
    padding: 0 10px;
  }
}
