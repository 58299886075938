/*primary colors*/
$white-cl: #fff;
$black-cl: #000;
$gray-cl: #d9d9d9;
$dark-gray-cl: #828282;
$brown-cl: #c89818;
$orange-cl: #ed6c01;
$blue-cl: #2d9cdb;
$dark-blue-cl: #1d81ba;
$paleBrown: #e9e9e9;
$primary_t1: #ed6c01;
$primary_t2: #ba5400;
$primary_t3: #fec08d;
$primary_t4: #fecea6;
$primary_t5: #733400;
$primary_t6: #aa4d00;
$primary_t7: #c95b00;
$primary_t8: #401d00;
$primary_t9: #fed9bb;

/*border color*/
$border-cl: rgba(0, 0, 0, 0.175);

/*border color*/
$background-cl: rgba(0, 0, 0, 0.03);

/*global text color*/
$text-black-cl: #000;
$text-gray-cl: #4f4f4f;

/*humberger button*/
$hamburger-button-gray: #6c757d;

// time transition
$t: 0.4s;
$cubic: cubic-bezier(0.84, 0.13, 0.33, 0.97);

/*font families*/
$font-stack: 'Open Sans', sans-serif;

/*headings typo*/
$fz-text: 16px;
$lh-text: normal;

$fz-text-m: 16px;
$lh-text-m: normal;

/*GRID - media queries breakpoints*/
$xxs-min: 340px;
$xs-min: 768px;
$sm-min: 992px;
$md-min: 1200px;
$lg-min: 1600px;
// Define custom breakpoint
$sm-min-custom: 768px;
$md-min-custom: 1280px;

$xxs-max: ($xxs-min - 1);
$xs-max: ($xs-min - 1);
$sm-max: ($sm-min - 1);
$md-max: ($md-min - 1);
$lg-max: ($lg-min - 1);

/*BOXED LAYOUT*/
$max-width: 1920px;
$container-max-width: $max-width;
$container-width: 1010px;

/*Text size*/
$text-sm-size: 16px;
$font-weight-normal: 400;

$text-bg: 22px;

$text-title: 20px;
$text-title-font-weigth: 300;

$text-line-height: 150%;

//Color
$gray-600: #6c757d;
$gray-900: #212529;

//goal color
$green: #04b153;
$red: #ff1919;
