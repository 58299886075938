@use '../../scss/variables' as v;
@import '../../scss/mixins';

.taskWarp {
  height: 430px;
  width: 70%;
  @include mobile {
    width: 100%;
  }
  :global(.ant-table-wrapper) {
    table {
      :global(.ant-table-cell) {
        button {
          max-width: 106px;
        }
      }
    }
  }
}

.taskTitle {
  font-weight: 400;
  font-size: 22px;
  line-height: 150%;
  margin: 80px 0 40px;
}

.taskTable {
  height: 200px;
  overflow: auto;
  @include mobile {
    padding-left: 0px;
  }
}

.pagination {
  margin-top: 12px;
}
