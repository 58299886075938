@use '../../../scss/variables' as v;
@import '../../../scss/mixins';

.groupsAdm {
  height: 100%;
  .groups {
    padding: 40px 70px;
    &__wrap {
      display: flex;
      flex-direction: column;
      :global(.ant-card-body) {
        height: calc(100% - 48px);
      }
      .assignmentsMenuTitle {
        display: flex;
        p:first-child {
          margin-right: 20px;
        }
      }
      .content {
        textarea {
          min-height: 120px;
        }
        .top {
          margin-bottom: 20px;
        }
        .contentWrap {
          border: 1px solid v.$black-cl;
          display: flex;
          padding: 16px;
          gap: 24px;
          .left {
            width: 60%;
            @include mobile {
              width: 100%;
            }
          }
          .right {
            width: 40%;
            img {
              max-width: 100%;
            }
            @include mobile {
              width: 100%;
            }
          }
          @include mobile {
            margin: 10px;
          }
        }
        .noteWrap {
          margin-bottom: 20px;
        }
        .uploadFileWrap {
          margin-bottom: 20px;
          margin-top: 30px;
        }
        .pagination {
          margin-top: 12px;
        }
        .previewImage {
          margin-top: 12px;
        }
      }
    }
  }
}
.title {
  margin-bottom: 10px;
}
.subLabel {
  margin-bottom: 5px;
}
