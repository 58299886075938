@use '../../../scss/variables' as v;
@import '../../../scss/mixins';

.groups {
  padding: 40px 70px;
  height: 100%;
  &__wrap {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
}

.top {
  height: calc(100% - 50px);
  flex: 1;
}
.body {
  font-size: 16px;
  p {
    margin: 26px 0;
  }
}
.bottom {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  height: 100%;
}

.assignmentsMenuTitle {
  font-size: 22px;
}
.topButtonGroup {
  margin-bottom: 22px;
}
.topButton {
  span {
    font-size: 16px;
    line-height: 100%;
  }
}
.assignmentTitle {
  font-size: 16px;
}
.assignmentItem {
  margin-bottom: 4px;
  width: 100%;
  div {
    width: 55%;
    input {
      font-size: 16px;
    }
  }
  input {
    width: 100%;
  }
  input,
  span {
    font-size: 16px;
  }
}

.confirmButton {
  margin: 40px 0;
  font-size: 16px;
  width: 120px;
  height: 40px;
  background: #ed6c01;
  border: none;
  color: white;
  border-radius: 2px;
  cursor: pointer;
}
:global {
  .ant-card-body {
    overflow: scroll;
    overflow-x: hidden;
  }
}
.pagination{
  text-align: center;
  margin-top: 1rem;
}
