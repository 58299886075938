@use '../../../scss/variables' as v;
@import '../../../scss/mixins';

.assignmentsResultsWrap {
  width: 100%;
  height: 100%;
  padding: 50px 70px 20px 70px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  line-height: 24px;
  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    border: 1px solid v.$border-cl;
  }
}

.title {
  padding: 10px;
  width: 100%;
  height: auto;
  display: flex;
  font-weight: v.$font-weight-normal;
  font-size: v.$text-bg;
  background-color: v.$background-cl;
  border-bottom: 1px solid v.$border-cl;
  p {
    margin-left: 10px;
  }
}

.content {
  padding: 25px;
  overflow-y: auto;
  button {
    margin-bottom: 20px;
  }
}

.tabs {
  flex: 1;
  overflow: auto;
  padding: 35px 0;
  @include mobile {
    margin: 10px;
  }
}
.tabsWrap {
  height: 100%;
  overflow: hidden;
}
