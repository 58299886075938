@use '../../../scss/variables' as v;
@import '../../../scss/mixins';

.groupsAdm {
  height: 100%;
  .groups {
    padding: 40px 70px;
    &__wrap {
      display: flex;
      flex-direction: column;
      :global(.ant-card-body) {
        height: calc(100% - 48px);
      }
      .assignmentsMenuTitle {
        display: flex;
        p:first-child {
          margin-right: 20px;
        }
      }
      .content {
        .top {
          margin-bottom: 20px;
        }
        .label,
        .value {
          font-size: 16px;
          line-height: 150%;
        }
        .label {
          font-weight: 300;
          color: $gray-900;
          margin-bottom: 6px;
        }
        .value {
          font-weight: 400;
          color: $gray-600;
          display: inline-block;
          margin-bottom: 14px;
          &:last-child {
            margin-bottom: 0;
          }
        }
        .sectionWrap {
          margin-top: 36px;
        }
        .contentWrap {
          border: 1px solid v.$black-cl;
          display: flex;
          padding: 16px;
          gap: 24px;
          .left {
            width: 60%;
            @include mobile {
              width: 100%;
            }
          }
          .right {
            width: 40%;
            img {
              max-width: 100%;
            }
            @include mobile {
              width: 100%;
            }
          }
          @include mobile {
            margin: 10px;
          }
        }
      }
    }
  }
}
