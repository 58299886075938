@use '../../../scss/variables' as v;
@import '../../../scss/mixins';

.topAdm {
  padding: 40px 70px;
  height: 100%;
}

.topAdmWarp {
  height: 100%;
  overflow: auto;
}

.cardContent {
  &__title {
    font-size: 20px;
  }
  .rowWrap {
    max-width: 900px;
    margin: 30px auto 0
  }
}


.item {
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 18px;
  &__top {
    border: 1px solid v.$gray-cl;
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 100px;
    }
  }
}

.borderItem {
  margin-top: 10px;
  border: 1px solid v.$gray-cl;
  padding: 18px 20px;
  width: 100%;
  text-align: center;
}

.subItem {
  border: 1px solid v.$gray-cl;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 18px;
  padding: 0px 16px;
  > p {
    padding: 20px 0;
  }
}

.pointer {
  cursor: pointer;
  &:hover {
    .borderItem, .subItem, .item__top {
      color: $blue-cl;
      border-color: $blue-cl;
    }
    img {
      // #2d9cdb
      filter: brightness(0) saturate(100%) invert(58%) sepia(74%) saturate(1883%) hue-rotate(173deg) brightness(89%) contrast(92%);
    }
  }

}

// Modal Groups
.contentModal {
  height: 70vh;
  overflow-y: scroll;
  .paginationWrapper {
    margin-top: 1rem;
    text-align: center;
  }
}

.actionBtn {
  text-align: center;
}
