@use '../../scss/variables' as v;
@import '../../scss/mixins';

.li {
  display: flex;
}
.formItem {
  display: flex;
  padding: 5px;
  border: 1px solid v.$border-cl;
  &__left {
    width: 60%;
    margin-bottom: 0px !important;
  }
  &__right {
    width: 40%;
    margin-bottom: 0px !important;
    margin-left: 5px !important;
  }
}
.buttonAdd {
  border: 0 !important;
  margin-left: 28%;
}
.iconAdd {
  font-size: large;
  color: v.$primary_t1 !important;
}
.iconRemove {
  font-size: large;
  color: v.$blue-cl !important;
}
.item {
  margin-top: 20px;
}
.label {
  display: flex;
  margin-left: 20px;
  height: 15px;
  &__left {
    width: 60%;
  }
  &__right {
    width: 40%;
  }
  input {
    border: none;
  }
}
