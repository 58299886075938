.mainWarp {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
}
.contentWrap {
  flex: 1;
  overflow-x: auto;
}
