@use '../../../scss/variables' as v;
@import '../../../scss/mixins';

.admAddCalculationSettingsGradeWrap {
  width: 100%;
  height: calc(100% - 40px);
  padding: 10px 30px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  line-height: 24px;
}
.title {
  margin-left: 76px;
}
.content {
  height: 100%;
  flex: 1;
  overflow: auto;
  padding-top: 35px;
  @include mobile {
    margin: 10px;
  }
}

.contentWrap {
  height: 100%;
  display: flex;
  &__left {
    width: 55%;
    border: 1px solid v.$border-cl;
    overflow: auto;
  }
  &__right {
    margin-left: 25px;
    width: 45%;
    border: 1px solid v.$border-cl;
    overflow: auto;
  }
}
.contentTitle {
  display: flex;
  align-items: center;
  border-bottom: 1px solid v.$border-cl;
  background: rgba(0, 0, 0, 0.03);
  height: 50px;
  padding-left: 10px;
  font-size: larger;
}
.divTable {
  // display: flex;
  align-items: center;
  width: 80%;
}
.form {
  padding: 10px;
}
.description {
  padding: 20px;
}
.titleCategory {
  font-size: x-large;
}
.divTable {
  margin: auto;
  margin-top: 10px;
}
.wrapDivTable {
  border-bottom: 1px solid v.$border-cl;
}
.rowTable {
  border: 1px solid v.$border-cl;
  border-bottom: 0;
}
.colTable {
  height: 50px;
  border-right: 1px solid v.$border-cl;
}
.titleCol {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  height: 100%;
}
.rowCategory {
  padding: 10px;
}
.inputNumber {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  height: 100%;
  width: 100% !important;
  border: 0px !important;
  :global(.ant-input-number-input) {
    height: 48px;
    font-size: large;
    color: #6c757d;
  }
}
.inputNumber:nth-child(2) {
  height: 100% !important;
}
.input {
  padding: 4px;
  padding-left: 12px;
  border: 1px solid #f0f0f0;
}
