@use '../../../../scss/variables' as v;
@import '../../../../scss/mixins';

.tabsWrap {
  height: 100%;
  overflow: hidden;
}

.subTitle {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  margin-left: 8px;
  font-size: 18px;
}

.heightText {
  font-size: 16px;
  margin-bottom: 24px;
}

.borderSubTitleB {
  border-bottom: 1px solid;
  width: 50% !important;
  margin-left: 30px;
}

.row2 {
  margin-top: 50px;
}
.tabScoll {
  overflow: 'auto';
}
.titleTable {
  margin-top: 2rem;
  text-align: center;
}
