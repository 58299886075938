@use '../../../scss/variables' as v;
@import '../../../scss/mixins';

.listWrap {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  li {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ced4da;
    &.itemActive {
      background: #e9ecef;
    }
    > div {
      height: 40px;
      display: flex;
      align-items: center;
      padding: 8px;
      :global(.ant-input-number) {
        height: 38px;
        width: 63px;
        border: 1px solid #ced4da;
        border-radius: 4px;
        margin: 0 4px;
        :global(.ant-input-number-input) {
          height: 38px;
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          color: #6c757d;
        }
      }
      span {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #212529;
      }
    }
    > div:first-child {
      width: 3.2%;
    }
    > div:nth-child(2) {
      width: 20%;
    }
    > div:last-child {
      width: 76.8%;
    }
    &:first-child {
      border-bottom: 2px solid #212529;
      &.itemActive {
        background: none;
      }
      div {
        span {
          font-weight: 700;
        }
      }
      > div:nth-child(2) {
        visibility: hidden;
      }
    }
  }
}

.assignmentsQuestionWrap {
  width: 100%;
  height: calc(100% - 40px);
  padding: 10px 30px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  line-height: 24px;
  .title {
    margin-left: 76px;
    @include mobile {
      margin-left: 16px;
    }
  }
  .content {
    height: 100%;
    flex: 1;
    overflow: hidden;
    padding-top: 35px;
    @include mobile {
      margin: 10px;
    }
    .contentWrap {
      height: 100%;
      display: flex;
      flex-direction: column;
      .titleContent {
        font-weight: 300;
        font-size: 16px;
        line-height: 150%;
        color: #212529;
        margin-bottom: 12px;
      }
    }
  }
}
